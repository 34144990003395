export default [
    {
      path: '/track/index',
      name: 'track/index',
      meta:{
        title:"追踪查询"
      },
      component: () => import('@/views/track/index')
    }
  ]
  