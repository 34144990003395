import { Toast } from 'vant';
export const iscode = (res, isShowSussessMessage,isTrueShow=true)=>{
  if(!res)return
  if(res.code === 0){
    isShowSussessMessage &&isTrueShow&& Toast.success(res.msg|| res.message);
    return true;
  } else {
    isShowSussessMessage && Toast(res.msg|| res.message||'服务器错误');
    return false;
  }
}
