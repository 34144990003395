<template>
  <div class="height-50 s_font_b fz-16 s_flex_center">选择商品</div>
  <van-search
    v-model="search.keyword"
    show-action
    placeholder="请输入物流码进行搜索"
  >
    <template #action>
      <div class="height-34 s_flex_center s_bf054 width-55 tw-text-white tw-rounded-sm" @click="onSearch">搜索</div>
    </template>
  </van-search>
  <div class="s_bf5" v-if='list.length'>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="我也是有底线的~"
        @load="onLoad"
      >
        <div class="s_flex_bian_c p-t-10 p-b-10 s_bf p-l-15 p-r-15" v-for="(item,index) in list" :key='index' @click="onSelect(item)">
          <img class="width-80 height-80 tw-rounded-sm" :src="info.oss_domain+item.product_image" alt="">
          <div class="width72">
            <p class="fz-15 s_omit2">{{item.product_name}}</p>
            <p class="tw-text-gray-400 m-t-5">规格：{{item.product_sku_name || '单规格'}}</p>
            <p class="tw-text-gray-400">商品编号：{{item.product_sn}}</p>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
  <van-empty v-else :image="require('@/assets/image/empty.png')" description="暂无商品" />
</template>

<script>
  import { ref, onMounted, inject, reactive, toRefs } from "vue";
  import { Toast } from 'vant';
  import { iscode } from '@/utils/app.js';
  import { SplitArray } from "@/utils/util";
	import { getProductSkuList } from '@/api/public.js';
  import store from '@/store'
  // 初始化默认筛选项数值
  let defSearch = {
    page: 1,
    limit: 10,
    keyword: ""
  };
  export default {
		name:"selectGoods",
    setup(props, { emit }) {
      const search = ref({ ...defSearch });
      const state = reactive({
        list:[],
        loading: false, // 是否处于加载状态，加载过程中不触发 load 事件
        finished: false, // 是否已加载完成，加载完成后不再触发 load 事件
        refreshing: false, // 是否处于加载中状态
        info:store.state.user.info
      })
      //获取列表
      const getList = async() =>{
        if (state.finished && state.loading) return
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
          duration:0
        });
				let result = await getProductSkuList({ ...search.value}).then(res => res.data).catch(error => error)
        console.log(result)
        Toast.clear();
				if (iscode(result)) {
					var listData = result.data || [];
					var list = SplitArray(listData, state.list);
					var finished = listData.length < search.value.limit;
					state.list = list;
					state.finished = finished;
          state.loading = false;
				}else{
          Toast.fail(result.msg);
				}
      }
      // 上拉加载
      const onLoad = () => {
        search.value.page =  search.value.page+1
			  getList();
      };
      //下拉刷新
      const onRefresh = () => {
        state.finished = false;
        setTimeout(()=>{
          state.list = [];
          search.value = { ...search.value, page: 1 };
          getList();
        },500)
      };
      const onSearch = (e) =>{
        onRefresh();
      }
      const onSelect = (item) =>{
        emit("select",item);
      }
      onMounted(() => {
        getList();
      })
      return {
        ...toRefs(state),
        search,
        onSearch,
        onSelect,
        onLoad,
        onRefresh
      };
    }
  };
</script>
