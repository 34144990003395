export default [
    {
      path: '/replace/index',
      name: 'replace/index',
      meta:{
        title:"标签替换"
      },
      component: () => import('@/views/replace/index')
    }
  ]
  