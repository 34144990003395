<template>
  <van-picker title="选择物流公司" :columns="columns" :columns-field-names="{text:'name'}" @confirm='confirm' @cancel='cancel' />
</template>

<script>
  import { ref, onMounted, reactive, toRefs } from "vue";
  import { Toast } from 'vant';
  import { iscode } from '@/utils/app.js';
	import { getExpress } from '@/api/public.js';
  export default {
		name:"selectLogistics",
    setup(props, { emit }) {
      const state = reactive({
        columns: []
      })
      const getList = async() =>{
        let result = await getExpress().then(res => res.data).catch(error => error)
        if (iscode(result)) {
					state.columns = result.data;
				}else{
          Toast.fail(result.msg);
				}
      }
      const confirm = (e) =>{
        emit("confirm", e);
      }
      const cancel = () =>{
        emit("cancel");
      }
      onMounted(() => {
        getList();
      })
      return {
        ...toRefs(state),
        confirm,
        cancel
      };
    }
  };
</script>
