<template>
  <van-picker v-if='columns.length' title="选择仓库" :columns="columns" :columns-field-names="fieldNames" @confirm='confirm' @cancel='cancel' />
  <van-empty v-else :image="require('@/assets/image/empty.png')" description="暂无仓库" />
</template>

<script>
  import { ref, onMounted, inject, reactive, toRefs } from "vue";
  import { Toast } from 'vant';
  import { iscode } from '@/utils/app.js';
	import { getWarehousePosition } from '@/api/public.js';
  import {useWare} from '@/hooks/function.js'
  export default {
		name:"selectWarehouse",
    props:{
      showChild:{
        type:Number
      }
    },
    setup(props, { emit }) {
      const state = reactive({
        columns: [],
        fieldNames:{}
      })
      const getList = async() =>{
        let result = await useWare().getWareList(props.showChild)
        if (iscode(result)) {
          if(props.showChild == 1){
            state.fieldNames = {text: 'name', values: 'id',children:'child'}
            result.data.map(v=>{v.child = v.child || []})
          }else{
            state.fieldNames = {text: 'name'}
          }
					state.columns = result.data;
				}else{
          Toast.fail(result.msg);
				}
      }
      const confirm = (e) =>{
        console.log(e)
        emit("confirm", e);
      }
      const cancel = () =>{
        emit("cancel");
      }
      onMounted(() => {
        getList();
      })
      return {
        ...toRefs(state),
        confirm,
        cancel
      };
    }
  };
</script>
