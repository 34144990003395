const state = {
  info:{}
}

// getters
const getters = {

}

// actions
const actions = {
 
}
// mutations
const mutations = {
  SET_USER_INFO(state, info){
    if(info){
      state.info = info;
    }else{
      state.info = {}
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
