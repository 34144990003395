<template>
  <div class="width100 s_bf" style="position: relative;left: 0;top: 0;">
    <div class="height-50 s_font_b fz-16 s_flex_center">选择{{typeText}}</div>
    <van-search
      v-model="search.keyword"
      show-action
      :placeholder="'请输入'+typeText+'进行搜索'"
    >
      <template #action>
        <div class="height-34 s_flex_center s_bf054 width-55 tw-text-white tw-rounded-sm" @click="onSearch">搜索</div>
      </template>
    </van-search>
  </div>
  <div class="p-t-10 p-l-10 p-r-10 s_bf5" v-if='list.length'>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="我也是有底线的~"
        @load="onLoad"
      >
        <div class="p-15 m-b-10 s_bf tw-rounded-sm" v-for="(item,index) in list" :key='index' @click="onSelect(item)">
          <p class="fz-14">{{item.warehousing_sn || item.refund_warehousing_sn || item.out_warehousing_sn}}</p>
          <van-divider :style="{ margin: '10px 0' }" />
          <!-- <p class="fz-14" v-if='orderTpye=="out"'>ERP订单：</p> -->
          <p class="fz-14 s_lin_h28" v-if='orderTpye=="in"||orderTpye=="return"'>商品数量：{{item.code_num || 0}}</p>
          <p class="fz-14 s_lin_h28" v-if='orderTpye=="out"'>收货人：{{item.to || '-'}}</p>
          <p class="fz-14 s_lin_h28" v-if='orderTpye=="out"'>出库仓库：{{item.warehouse_position_name || '-'}}</p>
          <p class="fz-14 s_lin_h28" v-if='orderTpye=="return"'>退货人：{{item.create_role_name || '-'}}</p>
          <p class="fz-14 s_lin_h28">创建时间：{{item.created_at || item.create_time}}</p>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
  <van-empty v-else :image="require('@/assets/image/empty.png')" :description="'未找到相关'+typeText" />
</template>

<script>
  import { ref, onMounted, inject, reactive, toRefs } from "vue";
  import { Toast } from 'vant';
  import { iscode } from '@/utils/app.js';
	import { getInWarehouseList } from '@/api/inWarehouse.js';
	import { getOutWarehouseStayList } from '@/api/outWarehouse.js';
	import { getRefundWarehouseList } from '@/api/refundWarehouse.js';
  import { SplitArray } from "@/utils/util";
  // 初始化默认筛选项数值
  let defSearch = {
    page: 1,
    limit: 10,
    keyword: ""
  };
  export default {
		name:"selectOrder",
    props:{
      orderTpye:{
        type:String
      }
    },
    setup(props, { emit }) {
      const search = ref({ ...defSearch });
      const state = reactive({
        list:[],
        typeText:'',
        loading: false, // 是否处于加载状态，加载过程中不触发 load 事件
        finished: false, // 是否已加载完成，加载完成后不再触发 load 事件
        refreshing: false, // 是否处于加载中状态
      })
      //获取列表
      const getList = async() =>{
        if (state.finished && state.loading) return
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
          duration:0
        });
        let result
        if(props.orderTpye == 'in'){
          result = await getInWarehouseList({ ...search.value}).then(res => res.data).catch(error => error)
        }else if(props.orderTpye == 'out'){
          result = await getOutWarehouseStayList({ ...search.value}).then(res => res.data).catch(error => error)
        }else{
          result = await getRefundWarehouseList({ ...search.value}).then(res => res.data).catch(error => error)
        }
        Toast.clear();
				if (iscode(result)) {
					var listData = result.data || [];
					var list = SplitArray(listData, state.list);
					var finished = listData.length < search.value.limit;
					state.list = list;
					state.finished = finished;
          state.loading = false;
				}else{
          Toast.fail(result.msg);
				}
      }
      // 上拉加载
      const onLoad = () => {
        search.value.page =  search.value.page+1
			  getList();
      };
      //下拉刷新
      const onRefresh = () => {
        state.finished = false;
        setTimeout(()=>{
          state.list = [];
          search.value = { ...search.value, page: 1 };
          getList();
        },500)
      };
      const onSearch = (e) =>{
        onRefresh();
      }
      const onSelect = (e) =>{
        emit("select",e);
      }
      onMounted(() => {
        state.typeText = props.orderTpye == 'out' ? '出库单' : (props.orderTpye == 'in' ? '入库单' : '退货单')
        getList();
      })
      return {
        ...toRefs(state),
        search,
        onSearch,
        onSelect,
        onLoad,
        onRefresh
      };
    }
  };
</script>
