const baseOss={
  dev: 'https://ydd-oss.2v2.co',
  pro: 'https://bcdt-oss.bcjyjt.com',
  uat:'https://ydd-oss.2vm.co'
}
export default {
  baseUrl: {
    dev: 'http://online-order-api.test.2vm.co/',
    pro: 'https://fch-api.bcjyjt.com/',
    uat:'http://online-order-api.uat.2vm.co/'
  },
  oss:baseOss[process.env.VUE_APP_ENV]
}
