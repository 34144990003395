export default [
  {
    path: '/outWarehouse/bySelect',
    name: 'outWarehouse/bySelect',
    meta:{
      title:"出库扫码"
    },
    component: () => import('@/views/outWarehouse/bySelect')
  },
  {
    path: '/outWarehouse/byFast',
    name: 'outWarehouse/byFast',
    meta:{
      title:"快速出库"
    },
    component: () => import('@/views/outWarehouse/byFast')
  },
  {
    path: '/outWarehouse/order',
    name: 'outWarehouse/order',
    meta:{
      title:"出库单"
    },
    component: () => import('@/views/outWarehouse/order')
  },
  {
    path: '/outWarehouse/orderDetail',
    name: 'outWarehouse/orderDetail',
    meta:{
      title:"出库单详情"
    },
    component: () => import('@/views/outWarehouse/orderDetail')
  },{
    path: '/outWarehouse/byFastCode',
    name: 'outWarehouse/byFastCode',
    meta:{
      title:"快速出库-扫码"
    },
    component: () => import('@/views/outWarehouse/byFastCode')
  },
  {
    path: '/outWarehouse/byOrder',
    name: 'outWarehouse/byOrder',
    meta:{
      title:"按单出库"
    },
    component: () => import('@/views/outWarehouse/byOrder')
  },
  {
    path: '/outWarehouse/byOrderCode',
    name: 'outWarehouse/byOrderCode',
    meta:{
      title:"按单出库-扫码"
    },
    component: () => import('@/views/outWarehouse/byOrderCode')
  },
  {
    path: '/outWarehouse/index',
    name: 'outWarehouse/index',
    meta:{
      title:"出库扫码"
    },
    component: () => import('@/views/outWarehouse/index')
  },
]
