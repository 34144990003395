import http from '@/plugins/request'
export const getUserInfo = (params) => {
    return http.request({
        url: 'fch/dealer/pda/pub',
        method: 'get',
        params
    })
}
// pda激活
export const pdaActive = (active_code,data) => {
    return http.request({
        url: 'fch/dealer/pda/active/'+active_code,
        method: 'POST',
        data
    })
}

// pda登录
export const pdaLogin = (data) => {
    return http.request({
        url: 'fch/dealer/pda/login',
        method: 'POST',
        data
    })
}

// 品牌商列表
export const getBrandsList = (params) => {
    return http.request({
        url: 'fch/pda/brands',
        method: 'get',
        params
    })
}