import http from '@/plugins/request'

//入库单列表
export const getInWarehouseList = (params) => {
    return http.request({
        url: 'fch/dealer/pda/inWarehouse',
        method: 'get',
        params
    })
}
// 入库单详情
export const getInWarehouseMore = (sn) => {
  return http.request({
      url: 'fch/dealer/pda/inWarehouse/'+sn,
      method: 'get'
  })
}
// 入库单二维码明细
export const getInWarehousingCode = (params) => {
  return http.request({
      url: 'fch/dealer/pda/inWarehouse/warehousingCode/'+params.sn,
      method: 'get',
      params
  })
}
//入库
export const setInWarehouse = (data) => {
  return http.request({
    url: 'fch/dealer/pda/inWarehouse',
    method: 'POST',
    data
  })
}
//生成入库单号
export const getInWarehouseSn = (data) => {
  return http.request({
    url: 'fch/dealer/pda/inWarehouse/sn',
    method: 'POST',
    data
  })
}
//后关联 - 提交操作
export const setAssociated = (data) => {
  return http.request({
    url: 'fch/dealer/pda/associated',
    method: 'POST',
    data
  })
}
//后关联 - 码信息
export const getAssociatedCode = (params) => {
  return http.request({
    url: 'fch/dealer/pda/associated/code',
    method: 'get',
    params
  })
}
//后关联 - 码信息
export const codeDissolve = (data) => {
  return http.request({
    url: 'fch/dealer/pda/dissolve',
    method: 'POST',
    data
  })
}
