import axios from 'axios';
import { iscode } from '@/utils/app';
import config from '@/config'
import { getStore, removeStore } from '@/utils/util'
import router from '@/router'
class HttpRequest {
  constructor (baseUrl) {
    this.baseUrl = baseUrl
  }
  interceptors (instance) {
    // 请求拦截
    instance.interceptors.request.use(config => {
      return config
    }, error => {
      return Promise.reject(error)
    })
    // 响应拦截
    instance.interceptors.response.use(res => {
      const { data, status } = res
        if (data.code == 10001) {
          removeStore('token')
          if(router.currentRoute._value.href!='/login'){
            router.push({ name: 'login' })
          }
        }else if(data.code == 20004){
            //  账号已在其它设备登录！
            router.push({path:'/login'})
        }else if(data.code == 20001){
            //  需要激活
            router.push({path:'/login?isAcive=1'})
        }else {
          iscode(data)
        }
        return { data, status }
    }, error => {
      if(error.response.data){
        iscode({
          code:0,
          msg:error.response.data.msg || ''
        },true)
      }
      console.log(error)
      return Promise.reject(error)
    })
  }
  request (options,headers={}) {
    const instance = axios.create()
    let Authorization = getStore('token')
    var config={}
    if(Authorization){
       config={
        baseURL: this.baseUrl,
        headers: {
          Authorization,
          ...headers,
          IMEI:getStore('IMEI'),
          "brand-id":getStore('brand_id')
        }
      }
    }else{
       config={
        baseURL: this.baseUrl,
        headers:{
          IMEI:getStore('IMEI'),
          "brand-id":getStore('brand_id')
        },
      }
    }
    for(var key in config.headers) {
      if(config.headers[key] === null) {
        delete config.headers[key]
      }
   }
    options = Object.assign(config, options)
    this.interceptors(instance)
    return instance(options)
  }
}
console.log(process.env.VUE_APP_ENV,'-env')
export const baseUrl =config.baseUrl[process.env.VUE_APP_ENV]
const   http = new HttpRequest(baseUrl)
export  default http
