import { createRouter, createWebHistory } from 'vue-router'
const indexRouter = {
  path: '/',
  component: () => import('@/views/index.vue'),
  redirect: '/index',
  children: []
}
var routes = [
  indexRouter,
]
const routerContext = require.context('./modules', true, /\.js$/)
routerContext.keys().forEach(route => {
  const routerModule = routerContext(route)
  indexRouter.children = [...indexRouter.children, ...(routerModule.default || routerModule)]
})
export default createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes
})
