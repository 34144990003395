export default [
  {
    path: '/returnGoods/index',
    name: 'returnGoods/index',
    meta:{
      title:"退货扫码"
    },
    component: () => import('@/views/returnGoods/index')
  },
  {
    path: '/returnGoods/order',
    name: 'returnGoods/order',
    meta:{
      title:"退货单"
    },
    component: () => import('@/views/returnGoods/order')
  },
  {
    path: '/returnGoods/orderDetail',
    name: 'returnGoods/orderDetail',
    meta:{
      title:"退货单详情"
    },
    component: () => import('@/views/returnGoods/orderDetail')
  }
]
