import http from '@/plugins/request'

//出库单列表
export const getOutWarehouseList = (params) => {
    return http.request({
        url: 'fch/dealer/pda/outWarehouse',
        method: 'get',
        params
    })
}
//待出库单列表
export const getOutWarehouseStayList = (params) => {
  return http.request({
      url: 'fch/dealer/pda/outWarehouseStay',
      method: 'get',
      params
  })
}
// 出库单详情
export const getOutWarehouseMore = (sn) => {
  return http.request({
      url: 'fch/dealer/pda/outWarehouse/'+sn,
      method: 'get'
  })
}
// 出库单扫码明细
export const getOutWarehouseCode = (params) => {
  return http.request({
      url: 'fch/dealer/pda/outWarehouse/code/'+params.sn,
      method: 'get',
      params
  })
}
// 产品扫码明细
export const getOutWarehouseProductScans = (params) => {
  return http.request({
      url: 'fch/dealer/pda/outWarehouse/productScans/'+params.out_warehousing_sn+'/'+params.out_warehouse_product_id,
      method: 'get',
      params
  })
}
// 确认出库检查
export const checkOutWarehouse = (sn) => {
  return http.request({
      url: 'fch/dealer/pda/outWarehouse/check/'+sn,
      method: 'get'
  })
}
//按单扫码入库
export const setOutWarehouseScan = (data) => {
  return http.request({
    url: 'fch/dealer/pda/outWarehouse/scan/'+data.out_warehousing_sn,
    method: 'POST',
    data
  })
}
//确认出库
export const setOutWarehouse = (data) => {
  return http.request({
    url: 'fch/dealer/pda/outWarehouse/send/'+data.out_warehousing_sn,
    method: 'POST',
    data
  })
}
//快速出库-创单
export const setOutWarehouseKs = (data) => {
  return http.request({
    url: 'fch/dealer/pda/outWarehouse/ks/out',
    method: 'POST',
    data
  })
}


// 未完成出货单
export const checkIncomplete = (sn) => {
  return http.request({
      url: 'fch/dealer/pda/outWarehouse/getIncomplete',
      method: 'get'
  })
}

// 清除未完成出货单
export const clearIncomplete = (params) => {
  return http.request({
      url: 'fch/dealer/pda/outWarehouse/'+params.out_warehousing_sn,
      method: 'DELETE',
      params
  })
}

// 出货扫描
export const qrScan = (data) => {
  return http.request({
      url: 'fch/dealer/pda/outWarehouse/scan',
      method: 'post',
      data
  })
}

// 更新订单
export const updateOrder = (data) => {
  return http.request({
      url: 'fch/dealer/pda/outWarehouse/update/'+data.out_warehousing_sn,
      method: 'post',
      data
  })
}

// 获取出库信息
export const productInfo = (params) => {
  return http.request({
      url: 'fch/dealer/pda/outWarehouse/product/'+params.out_warehousing_sn,
      method: 'get',
      params
  })
}

// 码明细
export const productCode = (params) => {
  return http.request({
      url: 'fch/dealer/pda/outWarehouse/code/'+params.out_warehousing_sn,
      method: 'get',
      params
  })
}

// 出货单详情
export const outWarehouseOrder = (params) => {
  return http.request({
      url: 'fch/dealer/pda/outWarehouse/'+params.sn,
      method: 'get',
      params
  })
}




