<template>
  <div class="height-50 s_font_b fz-16 s_flex_center">选择收货人</div>
  <van-tabs v-model:active="activeName" color='#5B9DFF' @click-tab="onClickTab">
    <van-tab title="经销商" :name="1"></van-tab>
    <van-tab title="门店" :name="2"></van-tab>
  </van-tabs>
  <van-search
    v-model="search.keyword"
    show-action
    placeholder="请输入名称/编号/手机号/负责人进行搜索"
  >
    <template #action>
      <div class="height-34 s_flex_center s_bf054 width-55 tw-text-white tw-rounded-sm" @click="onSearch">搜索</div>
    </template>
  </van-search>
  <div class="s_bf5" v-if='list.length'>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        :immediate-check='false'
        finished-text="我也是有底线的~"
        @load="onLoad"
      >
      <div class="p-t-10 p-l-15 p-r-15 s_bf" v-for="(item,index) in list" :key='index' @click="onSelect(item)">
        <div class="s_flex_bian_c p-b-10">
          <div class="width90">
            <p class="fz-15">{{item.name}}</p>
            <div class="m-t-7 tw-text-gray-400 width100 s_flex_ali">
              <p class="s_omit p-r-10" style="max-width: 50%;">{{item.leader_name}}</p>
              {{item.leader_phone}}
            </div>
            <p class="m-t-7 tw-text-gray-400">{{item.area || item.address}}</p>
          </div>
          <van-icon name="arrow" size='16px' color='#969799'/>
        </div>
        <van-divider :style="{ margin: '0' }" />
      </div>
      </van-list>
    </van-pull-refresh>
  </div>
  <van-empty v-else :image="require('@/assets/image/empty.png')" :description="activeName == 1?'暂无经销商':'暂无门店'" />
</template>

<script>
  import { ref, onMounted, inject, reactive, toRefs } from "vue";
  import { Toast } from 'vant';
  import { iscode } from '@/utils/app.js';
  import { SplitArray } from "@/utils/util";
	import { getConsignee } from '@/api/public.js';
  // 初始化默认筛选项数值
  let defSearch = {
    page: 1,
    limit: 10,
    keyword: ""
  };
  export default {
		name:"selectStore",
    setup(props, { emit }) {
      const search = ref({ ...defSearch });
      const state = reactive({
        list:[],
        activeName:1,
        loading: false, // 是否处于加载状态，加载过程中不触发 load 事件
        finished: false, // 是否已加载完成，加载完成后不再触发 load 事件
        refreshing: false, // 是否处于加载中状态
      })
      const onClickTab = (e) => {
        state.activeName = e.name
        onRefresh();
      }
      //获取列表
      const getList = async() =>{
        if (state.finished && state.loading) return
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
          duration:0
        });
				let result = await getConsignee({ ...search.value,type:state.activeName}).then(res => res.data).catch(error => error)
        Toast.clear();
				if (iscode(result)) {
					var listData = result.data || [];
					var list = SplitArray(listData, state.list);
					var finished = listData.length < search.value.limit;
					state.list = list;
					state.finished = finished;
          state.loading = false;
				}else{
          Toast.fail(result.msg);
				}
      }
      // 上拉加载
      const onLoad = () => {
        search.value.page =  search.value.page+1
			  getList();
      };
      //下拉刷新
      const onRefresh = () => {
        state.finished = false;
        state.list = [];
        search.value = { ...search.value, page: 1 };
        getList();
      };
      const onSearch = (e) =>{
        onRefresh();
      }
      const onSelect = (e) =>{
        e.out_warehousing_type = state.activeName;
        emit("select",e);
      }
      onMounted(() => {
        getList();
      })
      return {
        ...toRefs(state),
        search,
        onSearch,
        onSelect,
        onLoad,
        onRefresh,
        onClickTab
      };
    }
  };
</script>
