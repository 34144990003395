<template>
 <router-view />
</template>
<script>
  import { provide } from 'vue';
  import {useRoute,useRouter} from 'vue-router'
export default {
  name: "App",
  setup(){
    // 路由
    provide('$router',useRouter())
    provide('$route',useRoute())
  }
};
</script>
<style lang="scss">
@import "./styles/base.scss";
html,
body,
#app {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: #f5f5f5;
  -webkit-overflow-scrolling: touch;
}
</style>
