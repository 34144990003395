export default [
  {
    path: '/inWarehouse/index',
    name: 'inWarehouse/index',
    meta:{
      title:"入库扫码"
    },
    component: () => import('@/views/inWarehouse/index')
  },
  {
    path: '/inWarehouse/order',
    name: 'inWarehouse/order',
    meta:{
      title:"入库单"
    },
    component: () => import('@/views/inWarehouse/order')
  },
  {
    path: '/inWarehouse/orderDetail',
    name: 'inWarehouse/orderDetail',
    meta:{
      title:"入库单详情"
    },
    component: () => import('@/views/inWarehouse/orderDetail')
  }
]
