<template>
  <div class="content tw-flex tw-flex-col tw-justify-center tw-items-center">
    <img src="@/assets/image/empty.png" alt="" class="width60 m-b-10">
    <p class="width60 tw-text-center fz-14 ">{{tips|| '暂无数据'}}</p>
  </div>
</template>
<script>
export default {
  props: {
    tips: String,
  },
  name: "empty",
};
</script>