import { ref } from "@vue/reactivity";
import { getWarehousePosition } from '@/api/public.js';
import { onMounted } from "vue-demi";
export const useWare = (callback) => {
    const warehouseShow = ref(false)
    const defaultWare=ref()
    const getWareList=async (type)=>{
        let result = await getWarehousePosition({ type}).then(res => res.data).catch(error => error)
        defaultWare.value=result.data[0]
        return result
    }
    const warehouseConfirm = (data) => {
        warehouseShow.value = false
        callback(data)
    }
    const getDefaultWare=()=>{
        return defaultWare.value
    }
    onMounted(()=>{
        getWareList(1)
    })
    return {
        warehouseShow,
        warehouseConfirm,
        getWareList,
        getDefaultWare
    }
}
export const useGoods = (callback) => {
    const goodsShow = ref(false)
    const goodsConfirm = (data) => {
        goodsShow.value = false
        callback(data)
    }
    return {
        goodsShow,
        goodsConfirm
    }
}
export const useBatch = (callback) => {
    const batchShow = ref(false)
    const batchConfirm = (data) => {
        batchShow.value = false
        callback(data)
    }
    return {
        batchShow,
        batchConfirm
    }
}