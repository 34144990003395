import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import Vconsole from 'vconsole';
import 'lib-flexible/flexible'
import Vant from 'vant'
import 'vant/lib/index.css' // 全局引入样式
import '@/styles/index.scss'
import eventBus from './utils/eventBus'
import "tailwindcss/tailwind.css"
// 路由守卫
import './permission.js'
// let vConsole = new Vconsole()
const app = createApp(App);

// 注册通用组件
const requireComponent = require.context(
  // 其组件目录的相对路径
  './components',
  // 是否查询其子目录
  true,
  // 匹配基础组件文件名的正则表达式
  /\.vue$/
)
requireComponent.keys().forEach(fileName => {
  // 获取组件配置
  const componentConfig = requireComponent(fileName)
  // 全局注册组件
  app.component(
    componentConfig.default.name,
    // 如果这个组件选项是通过 `export default` 导出的，
    // 那么就会优先使用 `.default`，
    // 否则回退到使用模块的根。
    componentConfig.default || componentConfig
  )
})
app.use(Vant).use(store).use(router).mount('#app');
// app.use(vConsole)
app.use(eventBus)
