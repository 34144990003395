<template>
  <div>
    <audio id="audioPass" preload="auto">
      <source src="@/assets/audio/ok.mp3" type="audio/mp3" />
    </audio>
    <audio id="audioFail" preload="auto">
      <source src="@/assets/audio/error.mp3" type="audio/mp3" />
    </audio>
    <audio id="audioSuccess" preload="auto">
      <source src="@/assets/audio/success.wav" type="audio/mp3" />
    </audio>
  </div>
</template>

<script>
export default {
  name:"audioPlay",
  setup() {
    const error = () => {
      audioFail.play();
    };
    const pass = () => {
      audioPass.play();
    };
    const success = () => {
      audioSuccess.play();
    };
    return {
      error,
      pass,
      success,
    };
  },
};
</script>