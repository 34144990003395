import http from '@/plugins/request'

//退货单列表
export const getRefundWarehouseList = (params) => {
    return http.request({
        url: 'fch/dealer/pda/refund',
        method: 'get',
        params
    })
}
// 退货单详情
export const getRefundWarehouseMore = (sn) => {
  return http.request({
      url: 'fch/dealer/pda/refund/'+sn,
      method: 'get'
  })
}
// 退货单二维码明细
export const getRefundWarehousingCode = (params) => {
  return http.request({
      url: 'fch/dealer/pda/refund/code/'+params.refund_sn,
      method: 'get',
      params
  })
}
//创建退货单
export const setRefundWarehouse = (data) => {
  return http.request({
    url: 'fch/dealer/pda/refund/scan',
    method: 'POST',
    data
  })
}
//获取退货订单号
export const getRefundWarehouseSn = () => {
  return http.request({
    url: 'fch/dealer/pda/refund',
    method: 'post'
  })
}
