import router from './router'
import { getStore, setStore, removeStore } from '@/utils/util'
// 一门APP桥
import { jsBridge } from "@/assets/js/jsbridge-mini.js";
const whiteList = ['/login'];//白名单

import store from './store'
import {getUserInfo} from '@/api/index'
router.beforeEach(async (to, from, next) => {
  var isApp = false//是否是一门APP
  var IMEI = ''//设备标示
  // 判断环境是否APP
  if (/LT-APP/.test(navigator.userAgent)&&!getStore('IMEI')) {
    isApp = true
    jsBridge.ready(function () {
      jsBridge.getIMEI(function (id) {
        // id 设备标示
        setStore('IMEI', id)
      });
    });
  }
  // uniapp 打包
  if(window.plus){ 
    setStore('IMEI',plus.device.imei)
    // 在这里调用5+ API 
  }else{// 兼容老版本的plusready事件 
    document.addEventListener('plusready', function () {
      setStore('IMEI',plus.device.imei)
    })
  } 
  // 设置标题
  document.title=to.meta.title
  if (to.query.IMEI || getStore('IMEI')) {
    setStore('IMEI', to.query.IMEI || getStore('IMEI'))
    IMEI = to.query.IMEI || getStore('IMEI')
  }

  // 获取是否激活
  let userInfo = store.state.user.info;
  if(!userInfo.brand_info&&to.path!='/login'){
    // 获取详情
   let result=await getUserInfo().then(res=>res.data).catch(error=>error)
    // 未激活
    if(result.code!=0){
        next({path:'/login',query:{isAcive:1,IMEI:IMEI}})
    }else{
      store.commit('user/SET_USER_INFO', result.data);
      if (getStore('token')) {
        next()
      }else{
        next({path:'/login',query:{IMEI:IMEI}})
      }
    }
  }
  if (getStore('token')) {
    next()
  } else {
    // 如果是app
    if (isApp) {
      // 跳转登录需要检测是否激活
      next({path:'/login',query:{isAcive:1,IMEI:IMEI}})

    } else {
      if (whiteList.indexOf(to.path) !== -1) {
        console.log('-')
        next()
      } else {
        console.log(1)

        // 非app需要带标示进入  IMEI=xxxx
        next({path:'/login',query:{IMEI:IMEI}})
      }
    }
  }
})
router.afterEach(async (to) => {
  if (to.path === '/login') {
    removeStore('token')
  }
})
