export default [
    {
        path: '/relation/index',
        name: 'relation/index',
        meta: {
            title: "数据关联"
        },
        component: () => import('@/views/relation/index'),
    },{
        path: '/relation/second',
        name: 'second',
        meta: {
            title: "二级包装关联"
        },
        component: () => import('@/views/relation/second'),
    },{
        path: '/relation/three',
        name: 'three',
        meta: {
            title: "三级包装关联"
        },
        component: () => import('@/views/relation/three'),
    },{
        path: '/relation/dismiss',
        name: 'dismiss',
        meta: {
            title: "解除包装关联"
        },
        component: () => import('@/views/relation/dismiss'),
    }
]
