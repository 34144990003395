import http from '@/plugins/request'

//获取仓库
export const getWarehousePosition = (params) => {
    return http.request({
        url: 'fch/dealer/pda/warehousePosition',
        method: 'get',
        params
    })
}
// 获取产品sku列表
export const getProductSkuList = (params) => {
  return http.request({
      url: 'fch/dealer/pda/product/skuList',
      method: 'get',
      params
  })
}
// 获取产品批次
export const getProductBatch = (params) => {
  return http.request({
      url: 'fch/dealer/pda/productBatch',
      method: 'get',
      params
  })
}
// 获取收货人（门店/经销商）
export const getConsignee = (params) => {
  return http.request({
      url: 'fch/dealer/pda/outWarehouse/children',
      method: 'get',
      params
  })
}
// 物流公司
export const getExpress = (params) => {
  return http.request({
      url: 'fch/dealer/pda/express',
      method: 'get',
      params
  })
}
